<template>
  <div>
    <ProjectList v-if="user.role == 'admin'" />
    <EmpProjectList v-if="user.role == 'employee'" />
  </div>
</template>
<script>
import EmpProjectList from "./EmpProjectsList.vue";
import ProjectList from "./ProjectsList.vue";
export default {
  name: "ProjectListIndex",
  data() {
    return {
      user: {},
    };
  },
  components: {
    EmpProjectList,
    ProjectList,
  },
  mounted() {
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
  },
};
</script>
