<template>
  <div class="mt-2 px-0">
    <v-card tile class="pa-3" flat>
      <v-card-title>
        Project List
        <v-spacer></v-spacer>
        <v-text-field
          v-model.lazy="search"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          label="Search"
          single-line
          hide-details
          dense
          outlined
          class="border-radius-6"
          :style="{ maxWidth: '300px' }"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        :search="search"
        class="custom-data-table mx-4"
      >
        <template v-slot:item.name="{ item }">
          <div
            @click="$router.push(`/projects/${item.project_id}/board`)"
            style="cursor: pointer"
          >
            <Avatar
              :size="'30px'"
              :color="item.project_color_hex || 'blue lighten-3'"
              :intials="item.name ? item.name.charAt(0).toUpperCase() : 'P'"
              class="mr-2 my-3"
            />
            {{ item.name }}
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                :to="`/projects/${item.project_id}/planning`"
              >
                <CalendarBlankIcon />
              </v-btn>
            </template>
            <span>View Planning</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                v-bind="attrs"
                v-on="on"
                :to="`/projects/${item.project_id}/board`"
              >
                <EyeIcon />
              </v-btn>
            </template>
            <span>View Tasks</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="info"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Avatar from "../common/basic/Avatar.vue";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon.vue";
import EyeIcon from "../../assets/icons/EyeIcon.vue";
import { mapMutations } from "vuex";

export default {
  name: "EMPProjectList",
  components: {
    Avatar,
    CalendarBlankIcon,
    EyeIcon,
  },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    form_type_insert: false,
    form_title: "",
    message: "",
    errors: {},
    loading: false,
    snackbar: false,
    text: "hello",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    projects: [],
    item: {
      name: "",
    },
  }),
  computed: {
    filteredItems() {
      const queryStatus = this.$route.query.status;

      const status = queryStatus ? parseInt(queryStatus) : null;
      if (status) {
        return this.projects.filter((item) => item.project_status === status);
      }

      return this.projects;
    },
  },
  watch: {
    // Watch for route changes to trigger re-filtering
    "$route.query.status": function () {
      this.filteredItems;
    },
  },
  mounted() {
    this.get_project_list();
    this.$ws.$on("enable_project", this.handleSocketEnableProject);
  },

  methods: {
    ...mapMutations("projects", ["setProjectData"]),
    clearSearch() {
      this.search = "";
    },
    handleSocketEnableProject(data) {
      let enableProject = this.projects.findIndex(
        (item) => item.project_id == data[0].project_id
      );

      if (enableProject === -1) {
        this.projects;
      } else {
        this.projects.splice(enableProject, 1);
      }
    },
    redirect(project_id) {
      this.$router.push({ path: "/projects/" + project_id });
    },
    viewKanban(id) {
      this.$router.push({ name: "kanban2", params: { listId: id } });
    },
    manageProject(project_id) {
      this.$router.push({ path: "projects/manageProject/" + project_id });
    },
    submit() {
      if (this.form_type_insert) {
        this.save();
      } else {
        this.update();
      }
    },
    get_project_list() {
      this.loading = true;
      this.$axios.get("employee/project").then((res) => {
        this.projects = res.data.projects;
        this.setProjectData(res.data.projects);
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
