<template>
  <div class="full-width steps-indicator-container">
    <div v-for="(step, index) in steps" :key="index" :class="{ 'step': true, 'completed': step <= currentStep}" />
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 1
    },
    currentStep: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>
.steps-indicator-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 5px;
}

.step {
  background: var(--border-color);
  height: 6px;
  border-radius: 20px;
}

.step.completed {
  background: var(--primary-color);
}
</style>